@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=ABeeZee|Passion+One:700');

$primary: #0095da;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

* {
    font-family: 'ABeeZee', sans-serif;
}

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: transparent;
    transition: all 1s ease;

    @media (max-width: 767px) {
        background: $wht;
    }

    .navFade{
  background: transparent;
 
     .navbar-nav > li > a {
   color: $wht;
  }
 }
  
 .dark {
    color: $primary;
 }

 &.darkNav {
  background: rgba($wht, 1);
  border-bottom: 5px solid $primary;
 
    .navbar-nav > li > a {
   color: $wht;

   @media (max-width: 991px) {
    color: $blk;
   }
  }
 }

 .nav>li {
    text-align: center;
 }

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 36px;
            margin-left: 10px;
            display: flex;
            font-size: 18px;
            align-items: center;
            color: $wht;
            background: $primary;
            border-radius: 10px;
            @media (max-width: 767px) {
                background: transparent;
                color: $blk;
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
                text-align: center;
            }
            &:focus,
            &:active {
                background: $primary;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 0.5em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}
.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;

    .close {display: none;}

    .modal-content {
        color: $primary;

        h2 {
            text-align:center;
            font-size: 25px;
        }
    }

    a.btn, a.btn-default, button {
        @include btn
        margin: 0;
        display: block;
    }
}

.modal-header, .modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}



/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 350px;
    padding: 0.5em;
}

@media (max-width: 1024px) {
    .logo {
        max-width: 300px;
    }
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.static {
  background: transparent;

  @media (max-width: 991px) {
  background: url('../img/img1.jpg');
  background-size: cover;
  padding: 150px 0;
  background-position: 30% center;
    }
}

// SLIDESHOW START // 

.bss-slides{
  background: #000;
  position: relative;
  display: block;   
  margin-top: 0 !important;

  @media (max-width: 991px) {
    display: none;
  }
 }
.bss-slides:focus{
 outline: 0;
}
.bss-slides figure{
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 700px;

  @media (max-width: 1024px) {
    object-fit: contain;
    width: auto;
  }
}
.bss-slides figure:first-child{
  position: relative;
}
.bss-slides figure img{
  opacity: 0;
  -webkit-transition: opacity 1.2s;
  transition: opacity 1.2s;
  position: relative;
/*-webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  */
}
.bss-slides .bss-show {
  z-index: 3;
}
.bss-slides .bss-show img{
  opacity: 1;
  object-fit: cover;
 /* -webkit-backface-visibility: hidden;
  backface-visibility: hidden;*/
}

.bss-slides figcaption{
  position: absolute;
  font-family: sans-serif;
  font-size: .8em;
  bottom: .75em;
  right: .35em;
  padding: .25em;
  color: #fff;
  background: #000;
  background: rgba(0,0,0, .25);
  border-radius: 2px;
  opacity: 0;
  -webkit-transition: opacity 1.2s;
  transition: opacity 1.2s;
}
.bss-slides .bss-show figcaption{
  z-index: 2;
  opacity: 1;
}
.bss-slides figcaption a{
  color: #fff;    
}
.bss-next, .bss-prev{
  color: #fff;
  position: absolute;
  background: #000;
  background: rgba(0,0,0, .6);
  top: 50%;
  z-index: 4;
  font-size: 2em;
  margin-top: -1.2em;
  opacity: .5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bss-next:hover, .bss-prev:hover{
  cursor: pointer;
  opacity: 1;
}
.bss-next{
  right: 0;
  padding: 10px 5px 15px 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.bss-prev{
  left: 0;
  padding: 10px 10px 15px 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.bss-fullscreen{
  display: block;
  width: 24px;
  height: 24px;    
  background: rgba(0,0,0,.3) url(https://leemark.github.io/better-simple-slideshow/img/arrows-alt_ffffff_64.png); 
  -webkit-background-size: contain; 
  background-size: contain;
  position: absolute;
  top: 5px;
  left: 5px;   
  cursor: pointer;    
  opacity: .3;
} 
.bss-fullscreen:hover{
  opacity: .8;   
}
:-webkit-full-screen .bss-fullscreen{
    background: rgba(0,0,0,.4) url(https://leemark.github.io/better-simple-slideshow/img/compress_ffffff_64.png);
    -webkit-background-size: contain;
    background-size: contain;
}
:-moz-full-screen .bss-fullscreen{
    background: rgba(0,0,0,.4) url(https://leemark.github.io/better-simple-slideshow/img/compress_ffffff_64.png);
    background-size: contain;
}
:-ms-fullscreen .bss-fullscreen{
    background: rgba(0,0,0,.4) url(https://leemark.github.io/better-simple-slideshow/img/compress_ffffff_64.png);
    background-size: contain;
}
:full-screen .bss-fullscreen{
    background: rgba(0,0,0,.4) url(https://leemark.github.io/better-simple-slideshow/img/compress_ffffff_64.png);
    -webkit-background-size: contain;
    background-size: contain;
}
:-webkit-full-screen .bss-fullscreen{
    background: rgba(0,0,0,.4) url(https://leemark.github.io/better-simple-slideshow/img/compress_ffffff_64.png);
    -webkit-background-size: contain;
    background-size: contain;
}
:-moz-full-screen .bss-fullscreen{
    background: rgba(0,0,0,.4) url(https://leemark.github.io/better-simple-slideshow/img/compress_ffffff_64.png);
    background-size: contain;
}
:-ms-fullscreen .bss-fullscreen{
    background: rgba(0,0,0,.4) url(https://leemark.github.io/better-simple-slideshow/img/compress_ffffff_64.png);
    background-size: contain;
}
:fullscreen .bss-fullscreen{
    background: rgba(0,0,0,.4) url(https://leemark.github.io/better-simple-slideshow/img/compress_ffffff_64.png);
    -webkit-background-size: contain;
    background-size: contain;
}

// SLIDESHOW END

.pad {
    padding: 100px 0;
}

.color {
    background: $primary;
    color: $wht;
}

.featTitle {
    font-family: 'Passion One', sans-serif;
    text-align: center;
    @include fluid-type($min_width, $max_width, 23px, 28px);
}

.headTitle {
    font-family: 'Passion One', sans-serif;
    text-align: center;
    @include fluid-type($min_width, $max_width, 43px, 58px);
}

.featTxt {
    @include fluid-type($min_width, $max_width, 13px, 15px);
    text-align: center;
}

span {
    font-family: 'Passion One', sans-serif;
}

.title2 {
    text-align: center;
    margin-top: -10px;
    color: $wht;
}

.tagline {
    text-align: center;
    color: $wht;
}

.bg1 {
    background: url('../img/midsect.jpg');
    background-size: cover;
    background-attachment: fixed;
    padding: 100px 0;

    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
}

.wrap {
    overflow: hidden;
    max-width: 905px;
    color: #fff;
    background: #206398 url(//promo-theme.com/reka/wp-content/themes/reka/css/../images/line-pattern.png);
    padding: 15px 10px 35px;
    box-shadow: 0 5px 25px 0 rgba(0,82,227,.05);

    a {
        color: $wht;
    }
}

.contact {
    background: url('../img/contactbg.jpg');
    background-size: cover;
    background-position: center center;
    padding: 60px 0;
}

i {
    font-size: 50px;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.contact-row {
    position: relative;
    padding-left: 20px;
    margin: 20px 0;
    line-height: 11px;
    text-align: center;

    @media (max-width: 991px) {
      line-height: 20px;
    }
}

.img-center {
    display: block;
    margin: 0 auto;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.mvtxt {
  @media (max-width: 991px) {
    padding-top: 20px;
    line-height: 11px;
  }
}